<template>
  <div v-if="status != null && statusObj != null">
    <v-chip
      class="contract-status px-2"
      :small="!small"
      :x-small="small"
      :color="statusObj.color || 'secondary'"
      :text-color="statusObj.textColor || 'white'"
    >
      <i class="fas mr-2" :class="statusObj.icon" style="line-height: 0"></i>
      <span class="font-weight-medium" style="line-height: 0">{{
        statusObj.text.toUpperCase()
      }}</span>
    </v-chip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "contract-status",
  data() {
    return {
      statusObj: null
    };
  },
  props: {
    status: {
      type: Number,
      default: null
    },
    small: {
      type: Boolean,
      default: null
    }
  },
  mounted() {
    this.updateStatusObj();
  },
  methods: {
    updateStatusObj() {
      if (this.status != null) {
        this.statusObj = this.getEnumMember(Enums.CONTRACT_STATUS, this.status);
      }
    }
  },
  watch: {
    status: {
      handler() {
        this.updateStatusObj();
      }
    }
  }
};
</script>

<style lang="scss"></style>
