<template>
  <div v-if="type != null && typeObj != null">
    <v-chip
      class="contract-type px-2"
      :small="!small"
      :x-small="small"
      :color="typeObj.color || 'secondary'"
      :text-color="typeObj.textColor || 'white'"
    >
      <i class="fas" :class="[typeObj.icon, small ? 'mr-1' : 'mr-2']" style="line-height: 0"></i>
      <span style="line-height: 0; font-weight: 600">{{ typeObj.text }}</span>
    </v-chip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "contract-type",
  data() {
    return {
      typeObj: null,
    };
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateTypeObj();
  },
  methods: {
    updateTypeObj() {
      if (this.type != null) {
        this.typeObj = this.getEnumMember(Enums.ContractType, this.type);
      }
    },
  },
  watch: {
    type: {
      handler() {
        this.updateTypeObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
