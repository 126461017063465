import Api from "../../../Shared/services/api";
import { buildParams } from "../../../../plugins/helpers";

const baseUrl = "ExternalRoles";

export default {
  get() {
    return Api().get(baseUrl);
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  getRoleById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  exportExcel() {
    return Api().get(baseUrl + "/Export");
  },
  download() {
    return Api().get(baseUrl + "/download");
  },
};
