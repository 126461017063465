<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :selected-objects.sync="selectedObjects"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="entities"
    :total="total"
    :page.sync="options.page"
    :search.sync="options.search"
    :isLoading="isLoading"
    allow-search
    allow-paging
    allow-refresh
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    @refresh="onRefresh"
    itemValue="id"
    itemText="name"
  >
    <template v-slot:item="{ item }">
      <div class="d-flex align-center justify-start py-1" style="cursor: pointer; width: 100%">
        <div
          class="company-logo-mini rounded pa-0"
          style="overflow: hidden; border: 1px solid #cfd8dc"
        >
          <div style="height: 100%; width: 100%" v-if="item.logoUrl != null && item.logoUrl != ''">
            <img
              :key="item.id + '_img'"
              :src="item.logoUrl"
              height="100%"
              width="100%"
              style="object-fit: contain"
            />
          </div>
          <div style="height: 100%; width: 100%" v-else>
            <img
              :key="item.id + '_img'"
              src="/img/DNA_Symbol.png"
              height="100%"
              width="100%"
              style="object-fit: contain"
            />
          </div>
        </div>
        <div class="d-flex flex-column pl-2">
          <strong class="fs-13px">{{ item.name }}</strong>
          <span class="fs-11px mono-font opacity-72 font-weight-bold">
            <i class="fad fa-shapes fs-10px mr-1"></i>{{ item.productFamilies.length }}
          </span>
        </div>
      </div>
    </template>
  </filter-base-menu>
</template>

<script>
import { ManufacturerFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import manufacturersAPI from "../../../../Companies/Manufacturers/services/manufacturers-service.js";

export default {
  name: "manufacturer-filter",
  components: { FilterBaseMenu },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new ManufacturerFilterSettings(),
    },
    selectedManufacturers: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  data() {
    return {
      entities: [],
      selectedItems: [],
      selectedObjects: [],
      total: 0,
      timerId: null,
      storedSearch: null,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"],
        sortDesc: [false],
      },
      isLoading: true,
      initiallyLoaded: false,
    };
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
        this.getData();
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    onRefresh() {
      if (this.options.page == 1) {
        this.getData();
      } else {
        this.options.page = 1;
      }
    },
    getData() {
      if (this.storedSearch != this.options.search) this.options.page = 1;
      this.storedSearch = this.options.search;
      this.isLoading = true;
      manufacturersAPI
        .queryLiteWithFamilies(this.$clean(this.cloneDeep(this.options), true))
        .then((resp) => {
          if (this.options.page == 1) this.entities = resp.data.items;
          else this.entities.push(...resp.data.items);

          this.total = resp.data.total;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.timerId == null) {
        this.timerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.timerId);

      // delay new call 400ms
      this.timerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
    selectedObjects: {
      handler() {
        this.$emit("update:selected-manufacturers", this.cloneDeep(this.selectedObjects));
      },
      deep: true,
    },
  },
};
</script>
