import enums from "../../../../plugins/enums";

export class UserFilterSettings {
  type = enums.FILTER_TYPE.User.value;
  active = false;
  disabled = false;
  ref = "UserFilter";
  title = "User";
  model = "userIds";
}

export class ProjectSpaceCreatedByFilterSettings {
  type = enums.FILTER_TYPE.ProjectSpaceCreatedBy.value;
  active = false;
  disabled = false;
  ref = "ProjectSpaceCreatedByFilter";
  title = "Created By";
  model = "createdByIds";
  projectId = null;
}

export class ProjectSpaceUpdatedByFilterSettings {
  type = enums.FILTER_TYPE.ProjectSpaceUpdatedBy.value;
  active = false;
  disabled = false;
  ref = "ProjectSpaceUpdatedByFilter";
  title = "Updated By";
  model = "updatedByIds";
  projectId = null;
}

export class BuildingFilterSettings {
  type = enums.FILTER_TYPE.Building.value;
  active = false;
  disabled = false;
  ref = "BuildingFilter";
  title = "Building";
  model = "buildingIds";
  projectId = null;
}

export class LevelFilterSettings {
  type = enums.FILTER_TYPE.Level.value;
  active = false;
  disabled = false;
  ref = "LevelFilter";
  title = "Level";
  model = "levelIds";
  projectId = null;
}

export class ProviderFilterSettings {
  type = enums.FILTER_TYPE.Provider.value;
  active = false;
  disabled = false;
  ref = "ProviderFilter";
  title = "Provider";
  model = "providerIds";
}

export class InstallerFilterSettings {
  type = enums.FILTER_TYPE.Installer.value;
  active = false;
  disabled = false;
  ref = "InstallerFilter";
  title = "Installer";
  model = "installerIds";
}

export class EquipmentCategoryFilterSettings {
  type = enums.FILTER_TYPE.EquipmentCategory.value;
  active = false;
  disabled = false;
  ref = "CategoryFilter";
  title = "Equipment Category";
  model = "categoriesId";
}

export class ManufacturerFilterSettings {
  type = enums.FILTER_TYPE.Manufacturer.value;
  active = false;
  disabled = false;
  ref = "ManufacturerFilter";
  title = "Manufacturer";
  model = "manufacturerIds";
}

export class ProductFamilyFilterSettings {
  type = enums.FILTER_TYPE.ProductFamily.value;
  active = false;
  disabled = false;
  ref = "ProductFamilyFilter";
  title = "Product Family";
  model = "productFamilyIds";
}

export class SpaceTemplateFilterSettings {
  type = enums.FILTER_TYPE.SpaceTemplate.value;
  active = false;
  disabled = false;
  ref = "SpaceTemplateFilter";
  title = "Space Template";
  model = "spaceTemplateIds";
  projectId = null;
}

export class ClientFilterSettings {
  type = enums.FILTER_TYPE.Client.value;
  active = false;
  disabled = false;
  ref = "ClientFilter";
  title = "Client";
  model = "clientIds";
}

export class RoleFilterSettings {
  type = enums.FILTER_TYPE.Role.value;
  active = false;
  disabled = false;
  title = "Role";
  ref = "RoleFilter";
  model = "roleIds";
}

export class ExternalRoleFilterSettings {
  type = enums.FILTER_TYPE.ExternalRole.value;
  active = false;
  disabled = false;
  ref = "ExternalRoleFilter";
  title = "External Role";
  model = "roleIds";
}

export class ProjectStatusFilterSettings {
  type = enums.FILTER_TYPE.ProjectStatus.value;
  active = false;
  disabled = false;
  ref = "ProjectStatusFilter";
  title = "Project Status";
  model = "status";
  stage = null;
}

export class LeadStatusFilterSettings {
  type = enums.FILTER_TYPE.LeadStatus.value;
  active = false;
  disabled = false;
  ref = "LeadStatusFilter";
  title = "Lead Status";
  model = "status";
}

export class ContractTypeFilterSettings {
  type = enums.FILTER_TYPE.ContractType.value;
  active = false;
  disabled = false;
  ref = "ContractTypeFilter";
  title = "Contract Type";
  model = "type";
}

export class ContractOriginFilterSettings {
  type = enums.FILTER_TYPE.ContractOrigin.value;
  active = false;
  disabled = false;
  ref = "ContractOriginFilter";
  title = "Contract Origin";
  model = "origin";
}

export class ContractStatusFilterSettings {
  type = enums.FILTER_TYPE.ContractStatus.value;
  active = false;
  disabled = false;
  ref = "ContractOriginFilter";
  title = "Contract Status";
  model = "status";
}
