<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="entitiesFiltered"
    :total="total"
    :page.sync="options.page"
    :search.sync="options.search"
    :isLoading="isLoading"
    allow-search
    allow-refresh
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    @refresh="onRefresh"
    itemValue="id"
    itemText="name"
  >
  </filter-base-menu>
</template>

<script>
import { RoleFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import rolesAPI from "../../../../External/Roles/services/external-roles-service";

export default {
  name: "external-role-filter",
  components: { FilterBaseMenu },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new RoleFilterSettings(),
    },
  },
  mounted() {},
  data() {
    return {
      entities: [],
      selectedItems: [],
      total: 0,
      timerId: null,
      storedSearch: null,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 10,
        sortBy: ["firstName"],
        sortDesc: [false],
      },
      isLoading: true,
      initiallyLoaded: false,
    };
  },
  computed: {
    entitiesFiltered() {
      if (this.options.search != null && this.options.search.trim() != "") {
        let searchTerm = this.options.search.toLowerCase();
        return this.entities.filter((role) => role.name.toLowerCase().indexOf(searchTerm) > -1);
      } else return this.entities;
    },
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
        this.getData();
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    onRefresh() {
      this.getData();
    },
    getData() {
      this.isLoading = true;

      rolesAPI
        .get({
          itemsPerPage: -1,
        })
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = this.entities.length;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
